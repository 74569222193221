#primary-navigation {
  float: right;
}

ul#menu-header li.mobile-only {
  display: none;
}

@media (max-width: 1480px) {}


@media (max-width: 1280px) {}





@media (max-width: 1040px) {

  ul#menu-header li a {
    margin: 0 10px;
    font-size: 14px;
  }

  .primary-nav .button {
    height: 40px;
    line-height: 42px;
    font-size: 10px;
  }

  .menu-header-container {
    margin: 0 10px;
  }

}



@media (max-width: 920px) {


  a.logo.white object.logo-doctor {
    display: none;
  }


  .section.header.scrolled a.logo object.logo-doctor {
    right: 22px !important;
  }

  .section.blog .button.orange {
    margin-top: 20px;
  }

  .hero-container.home-hero-container span {
    bottom: 12vh;
  }

  .hero-text-box {
    bottom: 20px;
  }

  .hero-text-box h1 {
    font-size: 4.5vw;
  }

  h2 {
    font-size: 3.75vw;
  }

  h5 {
    font-size: 24px;
  }

  .hero-text-box {
    width: 90%;
  }

  .feature-filter .chosen-container-single .chosen-single span {
    font-size: 4vw;
    line-height: 4vw;
  }

  .feature-filter .chosen-container-single .chosen-single div b {
    background-size: 15px !important;
  }

  .feature-filter .chosen-container .chosen-results li {
    font-size: 3vw;
  }

  .hero-text-box p {
    font-size: 3vw;
    line-height: 5vw;
  }

  .job-row h3,
  .advisor-row.board-row .feature.column,
  .advisor-row .feature.column,
  .perks-column {
    width: 100% !important;
  }

  .advisor-row.board-row .feature.column,
  .advisor-row .feature.column {
    padding: 20px 0;
  }

  #team .feature.column {
    padding: 0 !important;
  }

  .job-row span {
    width: 50% !important;
  }

  .job-row {
    text-align: center;
  }

  .job-row a.button {
    display: inline-table;
    float: none;
  }

  /*.section {
    padding: 30px 0;
  }*/

  .mini-container {
    width: 100%;
    max-width: 100%;
  }

  .mini-container p {
    font-size: 4vw;
    line-height: 5vw;
  }

  .edit-link {
    display: none !important;
  }

  .home .section {
    height: 90vh !important;
  }

  .home .section.patients-section {
    height: 80vh !important;
  }

  .home .section.less-no-show {
    height: 87vh !important;
  }

  .chair_move {
    bottom: 110px;
  }

  .header.section {
    height: 70px !important;
    min-height: 70px !important;
    padding: 25px 30px !important;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
  }

  .section.header.active {
    background: #053c5e;
    height: 100vh !important;
  }

  .header.section.scrolled a.logo,
  .section.header.scrolled a.logo object,
  .section.header.scrolled a.logo svg,
  svg.logo-doctor,
  a.logo object {
    height: 30px !important;
  }

  .header.section a.logo {
    height: 30px !important;
    margin: 0;
    width: 200px;
    /*
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
*/
  }

  a.logo object.logo-doctor,
  a.logo object.logo-doctor-white {
    right: 22px;
  }

  .post-edit-link {
    line-height: 20px;
    margin-left: -25px;
  }

  .mobile-nav-wrapper {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 25px;
    width: 35px;
    height: 25px;
  }

  .mobile-nav,
  .mobile-nav:before,
  .mobile-nav:after {
    border-radius: 1px;
    height: 4px;
    width: 35px;
    background: #ff7c55;
    position: absolute;
    top: 10px;
    display: block;
    content: '';
  }

  .section.header.active svg.logo-doctor {
    fill: #fff !important;
  }

  .mobile-nav {
    width: 25px;
  }

  .mobile-nav:before {
    top: -10px;
  }

  .mobile-nav:after {
    bottom: -10px;
  }

  .mobile-nav,
  .mobile-nav:before,
  .mobile-nav:after {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
  }

  .mobile-nav-wrapper.active .mobile-nav {
    background-color: transparent;
  }

  .mobile-nav-wrapper.active .mobile-nav:before,
  .mobile-nav-wrapper.active .mobile-nav:after {
    top: 0;
    background: #fff;
  }

  .mobile-nav-wrapper.active .mobile-nav:before {
    transform: rotate(45deg);
  }

  .mobile-nav-wrapper.active .mobile-nav:after {
    transform: rotate(-45deg);
  }

  #primary-navigation {
    width: 100%;
    display: none;
    background: #053c5e;
    position: fixed;
    top: 60px;
    left: 0;
  }

  #primary-navigation.display {
    display: block;
  }

  .menu-header-container {
    width: 100%;
    padding: 0 35px;
  }

  ul#menu-header li a {
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 1.17;
    letter-spacing: -0.6px;
    color: #fff;
  }

  ul#menu-header,
  ul#menu-header li,
  ul#menu-header li a {
    width: 100%;
  }

  ul#menu-header,
  .header.section.scrolled ul#menu-header {
    margin: 50px 0;
  }

  ul#menu-header li {
    margin: 15px 0;
  }

  a.call-to-action.button.orange {
    display: none;
  }

  ul#menu-header li.mobile-only {
    display: block;
  }

  ul#menu-header li a.inquiry {
    color: #ff7c55;
  }


  .pricing-background {
    background: none;
    padding: 0 !important;
  }

  .roi-style {
    background: #D3EBFA;
    width: 100% !important;
    display: block;
    float: left;
    margin: 0;
    padding: 30px 40px;
  }

  .roi-style.projected input {
    font-size: 9vw;
  }

  .roi-style label {
    padding: 30px 0 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .roi-style label:first-child,
  .roi-style.projected label {
    border: none !important;
  }

  .hero-container {
    margin: 0;
    padding: 0;
    left: 0;
    top: 45%;
    margin-top: -30vh !important;
    width: 90%;
    margin: 0 5%;
  }

  .button.circle {
    bottom: 16vh;
  }

  .home-hero-container h1 {
    font-size: 3.5vh;
    line-height: 5vh;
  }

  .home-hero-container h1 .subtitle {
    font-size: 2.9vw !important;
    line-height: 4.8vw;
    margin: 30px;
  }

  .home .section h3 {
    font-size: 2.8vh !important;
    line-height: 3.9vh !important;
    margin-top: 20px;
  }

  .section-mobile .home-section-content {
    max-width: 100%;
    margin: 28vh auto 0 auto !important;
  }

  .home-section-content {
    max-width: 100%;
    margin: 31vh auto 0 auto !important;
  }

  .multiplelocation .home-section-content {
    max-width: 100%;
    margin: 27vh auto 0 auto !important;
  }
  .integration.section {
    height: 75vh!important;
  }

  .integration .home-section-content {
    max-width: 100%;
    margin: 35vh auto 0 auto !important;
  }

  .less-no-show .home-section-content {
    max-width: 100%;
    margin: 34vh auto 0 auto !important;
  }

  .patients-section .home-section-content {
    max-width: 100%;
    margin: 0 auto 0 auto !important;
  }

  .home-section-content p {
    font-size: 2.1vh !important;
    line-height: 3.5vh !important;
  }

  .home-section-content a.button.large {
    margin-top: 5vh;
  }

  .home-animation .object.background,
  .home-animation .object.buildings,
  .home-animation {
    height: 91vh !important;
  }

  .home-animation {
    width: 150vw;
    left: -50vw;
  }

  .section-object.wall {
    background-size: auto 85%;
  }


  .section-object.chair {
    width: 40vh;
    height: 15vh;
    margin-left: -20vh;
    top: 25vh;
  }

  .object.outlet-cloud {
    width: 40vh;
    height: 20vh;
  }

  .object.plug-cloud {
    width: 50vh;
    height: 25vh;
    right: 15vh;
  }

  .object.small-cloud {
    width: 12vh;
    height: 6vh;
  }

  .object.onion {
    width: 50vh;
    height: 20vh;
    margin-left: -25vh;
    margin-top: 10vh;
  }

  /*
.home-section-content.align-top {
  margin-top:10vh!important;
}
*/
  .object.mobile-phone {
    width: 40vh;
    height: 30vh;
    margin-left: -20vh;
    margin-top: -44vh;
  }

  .mobile-phone-display {
    width: 11.6vh;
    height: 20.6vh;
    margin-top: -40.65vh;
    margin-left: -5.95vh;
  }

  .display-wrapper {
    width: 46.4vh;
    height: 20.6vh;
  }

  .display-wrapper img {
    width: 11.6vh;
    height: 20.6vh;
  }

  .icon-object {
    width: 10vh;
    height: 10vh;
    margin-left: -5vh;
  }

  .icon-object.icon-mo1 {
    top: 10vh;
  }

  .icon-object.icon-mo2 {
    top: 15vh;
  }

  .icon-object.icon-mo3 {
    top: 20vh;
  }

  .icon-object.icon-mo4 {
    top: 25vh;
  }

  .icon-object.icon-mo1.active {
    margin-left: -17vh;
  }

  .icon-object.icon-mo2.active {
    margin-left: 8vh;
  }

  .icon-object.icon-mo3.active {
    margin-left: -17vh;
  }

  .icon-object.icon-mo4.active {
    margin-left: 8vh;
  }

  .section.section-mobile {
    height: 90vh !important;
  }
  .section.section-mobile .object {
    top:53%
  }

  .multiplelocation.section {
    height: 80vh !important;
  }

  .object.multiple-locations {
    width: 50vh;
    height: 25vh;
    margin-left: -25vh;
    margin-top: -34vh;
  }

  .object.location-flag.flag1 {
    margin-left: -20vh;
    margin-top: -29vh;
  }

  .object.location-flag.flag2 {
    margin-left: -15vh;
    margin-top: -18vh;
  }

  .object.location-flag.flag3 {
    margin-left: -7vh;
    margin-top: -35vh;
  }

  .object.location-flag.flag4 {
    margin-left: -7vh;
    margin-top: -24.5vh;
  }

  .object.location-flag.flag5 {
    margin-left: 12vh;
    margin-top: -32vh;
  }

  .object.location-flag.flag6 {
    margin-left: 13vh;
    margin-top: -21vh;
  }

  .object.location-flag.flag7 {
    margin-left: 19vh;
    margin-top: -44vh;
  }






  .object.location-flag.flag1.active {
    width: 3vh;
    height: 4.5vh;
    margin-left: -22vh;
    margin-top: -31vh;
  }

  .object.location-flag.flag2.active {
    width: 3vh;
    height: 4vh;
    margin-left: -17vh;
    margin-top: -20vh;
  }

  .object.location-flag.flag3.active {
    width: 4vh;
    height: 6vh;
    margin-left: -9vh;
    margin-top: -37vh;
  }

  .object.location-flag.flag4.active {
    width: 3vh;
    height: 4vh;
    margin-left: -8vh;
    margin-top: -25.5vh;
  }

  .object.location-flag.flag5.active {
    width: 3vh;
    height: 4vh;
    margin-left: 11vh;
    margin-top: -32vh;
  }

  .object.location-flag.flag6.active {
    width: 4vh;
    height: 5.5vh;
    margin-left: 12vh;
    margin-top: -22vh;
  }

  .object.location-flag.flag7.active {
    width: 3vh;
    height: 4vh;
    margin-left: 18vh;
    margin-top: -35vh;
  }


  .patients-adapt-section {
    background-size: 200% auto !important;
  }

  .patient-icon.icon1 {
    margin-left: -30vh;
    bottom: 46vh;
  }

  .patient-icon.icon2 {
    margin-left: -30vh;
    bottom: 33vh;
  }

  .patient-icon.icon3 {
    margin-left: -32vh;
  }

  .patient-icon.icon4 {
    margin-left: 12vh;
    bottom: 47vh;
  }

  .patient-icon.icon5 {
    margin-left: 14vh;
    bottom: 36vh;
  }

  .patient-icon.icon6 {
    margin-left: 18vh;
    bottom: 43vh;
  }

  .patient-icon.icon7 {
    margin-left: 21vh;
    bottom: 36vh;
  }

  .patient-icon {
    bottom: 40vh;
    width: 15vh;
    height: 15vh;
  }

  .call-to-action-section.section {
    height: auto !important;
  }

  .call-to-action-section h2 {
    font-size: 6vw;
    line-height: 8vw;
  }

  .call-to-action-section.section p {
    font-size: 4vw;
    line-height: 4vw;
  }

  .footer.section {
    padding: 30px;
    height: auto !important;
  }

  .home-animation .object.buildings {
    background-size: 125% auto;
  }

  .home-animation .object.patient {
    width: 6.5vh;
    height: 13vh;
    bottom: 2.75vh;
  }



  .home-animation .object.patient2 {
    width: 6.5vh;
    height: 13vh;
    bottom: 2.75vh;
  }




  .home-animation .object.patient2.step1,
  .home-animation .object.patient.step1 {

    opacity: 1;
    margin-left: -60vw;
    background: url(../images/patient1.png) no-repeat center center;
  }

  .home-animation .object.patient2.step1 {
    background: url(../images/femalepatient1.png) no-repeat center center;

  }

  .home-animation .object.patient2.step2,
  .home-animation .object.patient.step2 {

    background: url(../images/patient2.png) no-repeat center center;
  }

  .home-animation .object.patient2.step2 {
    background: url(../images/femalepatient2.png) no-repeat center center;

  }

  .home-animation .object.patient2.step3,
  .home-animation .object.patient.step3 {
    margin-left: -35vw;
  }

  .home-animation .object.patient2.step4,
  .home-animation .object.patient.step4 {
    margin-left: -10vw;
  }

  .home-animation .object.patient2.step5,
  .home-animation .object.patient.step5 {
    margin-left: 14vw;
  }

  .home-animation .object.patient2.step6,
  .home-animation .object.patient.step6 {
    margin-left: 38vw;
  }

  .home-animation .object.patient2.step7,
  .home-animation .object.patient.step7 {
    background: url(../images/patient3.png) no-repeat center center;
  }

  .home-animation .object.patient2.step7 {
    background: url(../images/femalepatient3.png) no-repeat center center;

  }

  .home-animation .object.patient2.step8,
  .home-animation .object.patient.step8 {
    background: url(../images/patient4.png) no-repeat center center;
  }

  .home-animation .object.patient2.step8 {
    background: url(../images/femalepatient4.png) no-repeat center center;
  }

  .home-animation .object.patient2.step9,
  .home-animation .object.patient.step9 {
    margin-left: 62vw;
    background: url(../images/patient2.png) no-repeat center center;
  }

  .home-animation .object.patient2.step9 {
    background: url(../images/femalepatient2.png) no-repeat center center;
  }

  .home-animation .object.patient2.step10,
  .home-animation .object.patient.step10 {
    bottom: 15vw;
    margin-left: 62vw;
    width: 2vw;
    height: 4vw;
    background: url(../images/patient2.png) no-repeat center center;
    opacity: 0;
  }

  .home-animation .object.patient2.step10 {
    background: url(../images/femalepatient2.png) no-repeat center center;

  }

  .home-animation .object.alarm {
    background: url(../images/alarm.png) no-repeat center center;
    width: 8vw;
    height: 8vw;
    background-size: 0 auto;
    z-index: 99;
    top: auto;
    bottom: 30vw;
    margin-left: 39vw;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
  }





  #team .team-member-bio {
    width: 100%;
    height: auto !important;
    min-height: auto !important;
  }

  #team .team-member {
    border: none !important;
    padding-bottom: 0 !important;
  }






  .home-animation .object.sun {
    width: 10vh;
    height: 10vh;
    margin-left: -30vh;
    margin-top: -17vh;
  }

  .home-animation .object.sun.setting {
    margin-left: -32vh;
    margin-top: 0vh;
  }

  .home-animation .object.sun.setting.finishsetting {
    margin-left: -35vh;
    margin-top: 10vh;
  }


  .home-animation .object.moon {
    display: none !important;
    width: 8vh;
    height: 8vh;
    margin-left: 20vh;
    margin-top: -17vh;
  }

  .home-animation .object.moon.setting {
    margin-left: 23vh;
    margin-top: 0vh;
  }

  .home-animation .object.moon.setting.finishsetting {
    margin-left: 25vh;
    margin-top: 10vh;
  }


  .header.section.scrolled ul#menu-header li a {
    color: #fff !important;
  }

  .header.section.scrolled ul#menu-header li a.inquiry {
    color: #ff7c55 !important;
  }



  .small-hero .hero-text-box h1 {
    font-size: 26px;
    margin: 30px 0;
  }

  .small-hero {
    height: 30vh;
  }

  .hero-text-box p {
    margin: 0 50px;
  }

  .roi-style.projected {
    background: #FFF;
    padding: 30px;
  }

  .ui-slider-horizontal {
    width: 50%;
  }

  svg.logo-doctor {
    display: block !important;
    float: left !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100px !important;
    height: 30px !important;
  }

  #clients .column.large-3 {
    display: block;
    float: left;
    width: 100% !important;
    margin: 0 !important;
    padding: 10px !important;
  }


  .client-logo {
    width: 50% !important;
    height: 0 !important;
    padding-bottom: 30% !important;
  }

  #team .team-member {
    width: 100%;
    margin: 0;
    border: 0;
    border-bottom: 1px solid #eee;
    padding: 0 10%;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .feature.column {
    padding: 0;
  }

  #team .feature-icon {
    overflow: hidden !important;
    height: 35vh !important;
    width: 100%;
    padding-right: 0;
  }

  #team .feature-icon img {
    display: block !important;
    float: left !important;
    width: 100% !important;
    height: auto !important;
  }

  .feature h3 {
    font-size: 36px;
    line-height: 44px;
    margin: 20px 0 0 !important;
    text-align: center;
  }

  .feature h4 {
    font-size: 18px;
    text-align: center;
  }

  .advisor-row .feature h3 {
    font-size: 36px;
  }

  .advisor-row .feature h4 {
    font-size: 22px;
    line-height: 32px;
  }

  .advisor-row .feature h5 {
    font-size: 18px;
    line-height: 30px;
  }

  .board-bio {
    padding: 0 10%;
  }

  .feature p {
    font-size: 18px;
  }

  .section-mobile .container:last-child {
    padding-top: 100px;
  }

  .object.mobile-phone {
    margin-top: -29vh;
  }

  .mobile-phone-display {
    margin-top: -25.65vh;
  }

  .display-wrapper {
    width: 46.4vh;
    height: 20.6vh;
  }

  .icon-object {
    width: 10vh;
    height: 10vh;
    margin-left: -5vh;
  }

  .icon-object.icon-mo1 {
    top: 25vh;
  }

  .icon-object.icon-mo2 {
    top: 30vh;
  }

  .icon-object.icon-mo3 {
    top: 35vh;
  }

  .icon-object.icon-mo4 {
    top: 40vh;
  }


  .post-title h2 {
    font-size: 26px;
  }

  a.more-link {
    bottom: 30px;
  }


  a.logo.white object.logo-doctor {
    display: none;
  }


}

.feature.column {
  padding: 30px 15px;
  text-align: center;
}

.feature-icon img {
  width: 30%;
}

.feature-icon {
  margin-bottom: 20px;
  height: auto;
  min-height: 100px;
  display: block;
  float: left;
  width: 100%;
}

.feature h4,
.feature p {
  display: block;
  float: left;
  width: 100%;
}

@media (min-width: 380px) and (max-width:800px) { 
  .home-hero-container h1 .subtitle {
    font-size: 4vw !important;
    line-height: 6.8vw;
    margin: 40px 0;
  }

  .home .section.less-no-show {
    height: 100vh !important;
  }
  .chair_move {
    bottom: 190px;
  }
  .integration.section {
      height: 85vh !important;
    }
    .multiplelocation.section {
      height: 90vh !important;
    }
    .object.multiple-locations {
      margin-top: -38vh;
    }
    .section.section-mobile {
      height: 103vh !important;
    }
   
    .section-mobile .home-section-content {
      margin: 26vh auto 0 auto !important;
    }
    .section.section-mobile .object {
      top:40%!important
    }
}


@media (max-width: 780px) and (min-width:480px) {

  .section.section-mobile {
    height: 120vh !important;
  }

  .object.mobile-phone {
    width: 40vw;
    height: 30vw;
    background-image: url(../images/iphone.png);
    background-size: auto 100%;
    background-position: center center;
    left: 50%;
    margin-left: -20vw;
    margin-top: -18vw;
    z-index: 99;
  }

  .mobile-phone-display {
    display: block;
    position: absolute;
    left: 50%;
    margin-top: -14.65vw;
    margin-left: -5.95vw;
    width: 11.6vw;
    height: 20.6vw;
    background: #000;
    z-index: 999;
    overflow: hidden;
  }

  .display-wrapper {
    display: block;
    float: left;
    width: 108vw;
    height: 20.6vw;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }

  .display-wrapper img {
    width: 21.6vh;
    height: 36.6vh;
  }

  .icon-object {
    display: block;
    position: absolute;
    width: 10vw;
    height: 10vw;
    background-size: auto 60%;
    background-repeat: no-repeat;
    z-index: 1;
    left: 50%;
    margin-left: -5vw;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
  }


  .icon-object.icon-mo1.active {
    margin-left: -17vw;
  }

  .icon-object.icon-mo2.active {
    margin-left: 8vw;
  }

  .icon-object.icon-mo3.active {
    margin-left: -17vw;
  }

  .icon-object.icon-mo4.active {
    margin-left: 8vw;
  }

  .section.section-mobile .section-content.home-section-content {
    margin: 53vh auto 0 auto !important;
  }



  .icon-object.icon-mo1 {
    top: 35vh;
  }

  .icon-object.icon-mo2 {
    top: 50vh;
  }

  .icon-object.icon-mo3 {
    top: 55vh;
  }

  .icon-object.icon-mo4 {
    top: 60vh;
  }




  .object.multiple-locations {
    width: 80vh;
    height: 35vh;
    margin-left: -38vh;
    margin-top: -39vh;
  }







  .object.plug-cloud {
    right: 5vh;
  }

  .section-object.wall {
    background-size: auto 100%;
  }



  .section.patients-adapt-section {
    background-position: bottom !important;
    height: 120vh !important;
  }

  .patients-adapt-section {
    background-size: 120% auto !important;
  }


  .patient-icon.icon1 {
    margin-left: -45vh;
    bottom: 52vh;
  }

  .patient-icon.icon2 {
    margin-left: -30vh;
    bottom: 33vh;
  }

  .patient-icon.icon3 {
    margin-left: -45vh;
  }

  .patient-icon.icon4 {
    margin-left: 20vh;
    bottom: 54vh;
  }

  .patient-icon.icon5 {
    margin-left: -20vh;
    bottom: 27vh;
  }

  .patient-icon.icon6 {
    margin-left: 28vh;
    bottom: 42vh;
  }

  .patient-icon.icon7 {
    margin-left: 14vh;
    bottom: 30vh;
  }


  .small-hero {
    height: 55vh;
  }

  #team .feature-icon {
    height: 350px !important;
  }


  ul#menu-header,
  .header.section.scrolled ul#menu-header {
    margin: 10px 0 0;
  }

  ul#menu-header li {
    margin: 10px 0;
  }

  ul#menu-header li a {
    font-size: 16px !important;
  }



}



@media (max-width: 480px) {

  .hero-text-box p {
    font-size: 4vw;
  }

  .content-hero {
    height: 120vw;
    background-size: auto 25% !important;
    background-position: center 30vw;
  }

  .content-hero-wrapper {

    background: none;
  }

  .hero-text-box {
    bottom: 50px;
  }

  .hero-text-box h1 {
    font-size: 36px;
  }



  .content-hero .button.circle {
    display: none;
  }




  .small-hero {
    height: 60vw;
  }

  .hero-text-box.small {
    top: 18vw;
  }



  .section {}


  h2 {
    font-size: 5vw;
    line-height: 6vw;
  }





  .object.mobile-phone {
    margin-top: -24vh;
  }

  .mobile-phone-display {
    margin-top: -20.65vh;
  }

  .icon-object.icon-mo1 {
    top: 22vh;
  }

  .icon-object.icon-mo2 {
    top: 27vh;
  }

  .icon-object.icon-mo3 {
    top: 32vh;
  }

  .icon-object.icon-mo4 {
    top: 37vh;
  }


  .patient-icon.icon1 {
    margin-left: -44vw;
  }

  .patient-icon.icon2 {
    margin-left: -30vw;
  }

  .patient-icon.icon3 {
    margin-left: -18vw;
  }

  .patient-icon.icon4 {
    margin-left: 1vw;
  }

  .patient-icon.icon5 {
    margin-left: 13vw;
  }

  .patient-icon.icon6 {
    margin-left: 27vw;
  }

  .patient-icon.icon7 {
    margin-left: 39vw;
  }

  .home-section-content a.button.large {
    margin-top: 0;
  }

  .patient-icon {
    bottom: 26vh !important;
    width: 10vh;
    height: 20vh;
  }


  .patient-icon.icon7 {
    margin-left: -11vh;
  }



  #share .button {
    margin: 0 0 18.72px;
  }


  .feature.column {
    border-bottom: 1px solid #e5e5e5;
  }

  .feature.column:last-child {
    border: none !important;
  }

  .integrated.column h3 {
    bottom: 0 !important;
    font-size: 2.8vw !important;
  }


  .integrated-icon {
    background-size: 75%;
    background-position: center 45%;
  }





  .roi-style label {
    padding-top: 20px;
  }

  /* team */

  #team .team-member {
    margin-top: 30px;
  }

  #team .row {
    padding: 0;
  }

  #team .feature-icon {
    width: 100%;
    height: auto;
    padding: 0;
  }


  .team-member p,
  .feature p {
    text-align: left;
  }

  .feature h3,
  .advisor-row .feature h3 {
    font-size: 4.5vw;
    line-height: 24px;
    margin: 0 !important;
  }

  .feature h4 {
    margin-top: 0;
    font-size: 14px;
  }

  .feature p {
    font-size: 14px;
  }

  .advisor-row .feature h4 {
    font-size: 14px;
    line-height: 22px;
  }

  .advisor-row .feature h5 {
    font-size: 14px;
    line-height: 10px;
  }


  .feature-filter select {
    width: 150px !important;
  }

  .chosen-container-single .chosen-single div {
    height: 15px;
  }

  /* contact */
  /*.contactform {
    padding: 30px;
  }*/

  .integrated.column.large-3.small-6 {
    width: 48%;
  }

  .integrated-icon {
    width: 135px;
    height: 135px;
  }


  #case-studies .content-row {
    padding: 20px;
    margin: 10px 0;
    width: 100%;
  }

  .problem {
    width: 100%;
    font-size: 16px !important;
    line-height: 1.5 !important;
  }

  .content-row .problem:before {
    top: 24px;
    right: 20px;
  }

  .solution p {
    margin-top: 20px !important;
  }




  .column.large-6.post-title {
    width: 100%;
    height: auto;
    margin: 0;

  }


  .post-title h2 {
    height: auto;
  }


  .section.blog .button {
    padding: 0;
    font-size: 10px;
    height: 40px;
    line-height: 42px;
  }

  .blog h2 {
    font-size: 26px !important;
  }

  .blog p {
    font-size: 16px !important;
  }

  .es_shortcode_form {
    float: left;
  }




  .dropdown::after {
    display: block;
    width: 20px;
    height: 20px;
    background-size: 14px;
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
  }


  .button.st-custom-button {
    padding: 10px;
  }

  .st-custom-button svg {
    margin: 0;
  }

  .st-custom-button span {
    display: none;
  }


  .pricing-description-box {
    float: right;
  }

  .pricing-description {
    left: -187px;
  }

  .pricing-description:after {
    left: 80%;
    margin-left: -2px;

  }




}


@media (max-width: 380px) {
  .page-id-17 p {
    font-size: 13px;
  }


}