/*@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular-webfont.woff2') format('woff2'),
        url('../fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold-webfont.woff2') format('woff2'),
        url('../fonts/poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinslight';
    src: url('../fonts/poppins-light-webfont.woff2') format('woff2'),
        url('../fonts/poppins-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}*/

/* This stylesheet generated by Transfonter (https://transfonter.org) on March 16, 2017 12:13 PM */

@font-face {
    font-family: 'SegoeUILight';
    src: url('../fonts/segoeui/SegoeUI-Light.eot');
    src: url('../fonts/segoeui/SegoeUI-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoeui/SegoeUI-Light.woff') format('woff'),
        url('../fonts/segoeui/SegoeUI-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUIBlack';
    src: url('../fonts/segoeui/SegoeUIBlack.eot');
    src: url('../fonts/segoeui/SegoeUIBlack.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoeui/SegoeUIBlack.woff') format('woff'),
        url('../fonts/segoeui/SegoeUIBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUIBold';
    src: url('../fonts/segoeui/SegoeUI-Bold.eot');
    src: url('../fonts/segoeui/SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoeui/SegoeUI-Bold.woff') format('woff'),
        url('../fonts/segoeui/SegoeUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUISemilight';
    src: url('../fonts/segoeui/SegoeUI-Semilight.eot');
    src: url('../fonts/segoeui/SegoeUI-Semilight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoeui/SegoeUI-Semilight.woff') format('woff'),
        url('../fonts/segoeui/SegoeUI-Semilight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI';
    src: url('../fonts/segoeui/SegoeUI.eot');
    src: url('../fonts/segoeui/SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoeui/SegoeUI.woff') format('woff'),
        url('../fonts/segoeui/SegoeUI.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUISemibold';
    src: url('../fonts/segoeui/SegoeUI-Semibold.eot');
    src: url('../fonts/segoeui/SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/segoeui/SegoeUI-Semibold.woff') format('woff'),
        url('../fonts/segoeui/SegoeUI-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

a,
a:active,
a:focus,
button,
button:focus,
button:active,
.btn,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn.active.focus {
    outline: none;
    outline: 0;
}

input::-moz-focus-inner {
    border: 0;
}

:focus {
    outline: none;
}

/* Font smoothing */
* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

body {
    color: rgba(0, 0, 0, .8);
    /*letter-spacing: 0.7px;*/
    font-family: 'SegoeUI';
    font-size: 15px;
}

h1 {
    font-size: 3.1rem;
    font-family: 'SegoeUIBold';
}

h2,
h3 {
    font-size: 1.75vw;
    margin-bottom: 1.5rem;
}

h2,
h3,
h4,
h5 {
    font-family: 'SegoeUIBold';
}

h6 {
    font-family: 'SegoeUISemiBold';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #053c5e;
    line-height: 1.5;
}

h5 {
    color: #053c5e;
    font-size: 1rem;
    font-family: 'SegoeUI';
}


h6 {
    color: #229be5;
}

a {
    text-decoration: none;
}

/*Header CSS*/

header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    background-color: #e8f5fc;
}

header.sticky {
    box-shadow: 0 1.5625rem 2.8125rem rgba(47, 46, 65, 0.075);
    position: fixed;
    background-color: #fff;
    transition: none 0s ease 0s;
    width: 100%;
    top: 0;
    z-index: 1000;
    border-bottom: 1px solid #ddd
}

header.sticky .blureffect {
    backdrop-filter: blur(4px);
}

.header-link a {
    margin: 0 0 0 40px;
    color: #053C5E;
    font-size: 15px;
    font-family: 'SegoeUISemiBold';
}

.header-link a.active {
    font-family: 'SegoeUIBold';
    color: #053C5E;
}

.appt-btn {
    border-radius: 50px;
    padding: 10px 20px 10px;
    margin: 10px 0 10px 40px;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 13px;
}

.appt-btn a {
    color: #fff !important;
    margin: 0;
}

.appt-btn a:hover {
    font-weight: normal;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
    border: 0;
    background-color: #ff8e3a !important;
}

.nav-links.open {
    display: block;
}

.nav-links {
    display: none;
}

.nav-link .dropdown {
    position: relative;
    display: inline;
}
.nav-link .dropdown > a.active{font-family: 'SegoeUISemiBold'; padding-bottom: 23px;}

.nav-link .dropdown a.arrowicon::after {
    border-bottom: 0;
    border-left: .3em solid #0000;
    border-right: .3em solid #0000;
    border-top: .3em solid;
    content: "";
    display: inline-block;
    margin-left: 0.555em;
    vertical-align: .095em;
  }

.nav-link .submenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 1px 1px 4px rgba(0,0,0,.15);
    padding: 10px 5px ;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    margin: 0 0 0 40px;
    top: 40px;
    border-top:3px solid #ff8e3a;
}

.nav-link .submenu a {
    padding: 10px 10px;
    white-space: nowrap;
    margin: 0;
}

/*END Header CSS*/

/*Home Banner Animation*/

.subtitle {
    font-size: 1.5vw;
    line-height: 2vw;
    font-weight: Normal;
    font-family: 'SegoeUISemiLight';
}

.hero.section {
    background: #e8f5fc;
}

.section {
    position: relative;
    padding: 50px 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
}


.section .object {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.hero-container {
    position: absolute;
    z-index: 99;
    text-align: center;
    width: 55vw;
    left: 23vw;
}

.hero-container h1 {
    color: #053C5E;
    font-size: 22px;
    -webkit-transition: all 3s ease;
    -moz-transition: all 3s ease;
    -o-transition: all 3s ease;
    transition: all 3s ease;

}

.hero-container h1.night {
    color: #fff;
}

.hero-container.home-hero-container {
    color: #FF8E3A;
}

.home-hero-container h1 {
    font-size: 2.60vw;
    line-height: 3.8vw;
}

.home-hero-container h1 .subtitle {
    margin-top: 25px;
    line-height: 2.8vw;
}

.home-hero-container {
    position: absolute;
    bottom: 28vw;
}

.home-animation {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 56vw;
}

.home-animation .object {
    display: block;
    position: absolute;
}

.home-animation .object.buildings {
    width: 100%;
    height: 57vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(../images/buildings.webp) no-repeat bottom center;
    background-size: 100% auto;
    z-index: 9;
}

.home-animation .object.background {
    background: #e8f5fc;
    z-index: 5;
    width: 100%;
    height: 56vw;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: all 6s ease;
    -moz-transition: all 6s ease;
    -o-transition: all 6s ease;
    transition: all 6s ease;
    opacity: 1;
}

.home-animation .object.sun {
    width: 10vw;
    height: 10vw;
    background: url(../images/sun.webp) no-repeat center center;
    background-size: 100% auto;
    z-index: 8;
    margin-left: -35vw;
    margin-top: -17vw;
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s linear;
    -o-transition: all 1.5s linear;
    transition: all 1.5s linear;

    opacity: 1;
}

.home-animation .object.sun.setting {
    margin-left: -38vw;
    margin-top: 0vw;
    opacity: 1;
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s linear;
    -o-transition: all 1.5s linear;
    transition: all 1.5s linear;

}

.home-animation .object.sun.setting.finishsetting {
    margin-left: -40vw;
    margin-top: 10vw;
    opacity: 0;
    -webkit-transition: all 1.3s linear;
    -moz-transition: all 1.3s linear;
    -o-transition: all 1.3s linear;
    transition: all 1.3s linear;
}


.home-animation .object.moon {
    width: 8vw;
    height: 8vw;
    background: url(../images/moon.webp) no-repeat center center;
    background-size: 100% auto;
    z-index: 8;
    margin-left: 25vw;
    margin-top: -17vw;
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s linear;
    -o-transition: all 1.5s linear;
    transition: all 1.5s linear;
    opacity: 1;
}

.home-animation .object.moon.setting {
    margin-left: 28vw;
    margin-top: 0vw;
    opacity: 1;
    -webkit-transition: all 1.5s linear;
    -moz-transition: all 1.5s linear;
    -o-transition: all 1.5s linear;
    transition: all 1.5s linear;
}

.home-animation .object.moon.setting.finishsetting {
    margin-left: 30vw;
    margin-top: 10vw;
    opacity: 0;
    -webkit-transition: all 1.3s linear;
    -moz-transition: all 1.3s linear;
    -o-transition: all 1.3s linear;
    transition: all 1.3s linear;
}

.home-animation .object.patient {
    width: 6.5vw;
    height: 13vw;
    background: url(../images/patient1.webp) no-repeat center center;
    background-size: auto 100% !important;
    z-index: 99;
    bottom: 2.75vw;
    left: 50%;
    margin-left: -60vw;
    top: auto;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    opacity: 0;
}


.home-animation .object.patient2 {
    width: 6.5vw;
    height: 13vw;
    background: url(../images/femalepatient1.webp) no-repeat center center;
    background-size: auto 100% !important;
    z-index: 99;
    bottom: 2.75vw;
    left: 50%;
    margin-left: -60vw;
    top: auto;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    opacity: 0;
}


.home-animation .object.patient2.step1,
.home-animation .object.patient.step1 {

    opacity: 1;
    margin-left: -32vw;
    background: url(../images/patient1.webp) no-repeat center center;
}

.home-animation .object.patient2.step1 {
    background: url(../images/femalepatient1.webp) no-repeat center center;

}

.home-animation .object.patient2.step2,
.home-animation .object.patient.step2 {

    background: url(../images/patient2.webp) no-repeat center center;
}

.home-animation .object.patient2.step2 {
    background: url(../images/femalepatient2.webp) no-repeat center center;

}

.home-animation .object.patient2.step3,
.home-animation .object.patient.step3 {
    margin-left: -18vw;
}

.home-animation .object.patient2.step4,
.home-animation .object.patient.step4 {
    margin-left: -5vw;
}

.home-animation .object.patient2.step5,
.home-animation .object.patient.step5 {
    margin-left: 7vw;
}

.home-animation .object.patient2.step6,
.home-animation .object.patient.step6 {
    margin-left: 19vw;
}

.home-animation .object.patient2.step7,
.home-animation .object.patient.step7 {
    background: url(../images/patient3.webp) no-repeat center center;
}

.home-animation .object.patient2.step7 {
    background: url(../images/femalepatient3.webp) no-repeat center center;

}

.home-animation .object.patient2.step8,
.home-animation .object.patient.step8 {
    background: url(../images/patient4.webp) no-repeat center center;
}

.home-animation .object.patient2.step8 {
    background: url(../images/femalepatient4.webp) no-repeat center center;
}

.home-animation .object.patient2.step9,
.home-animation .object.patient.step9 {
    margin-left: 32vw;
    background: url(../images/patient2.webp) no-repeat center center;
}

.home-animation .object.patient2.step9 {
    background: url(../images/femalepatient2.webp) no-repeat center center;
}

.home-animation .object.patient2.step10,
.home-animation .object.patient.step10 {
    bottom: 9vw;
    margin-left: 32vw;
    width: 2vw;
    height: 4vw;
    background: url(../images/patient2.webp) no-repeat center center;
    opacity: 0;
}

.home-animation .object.patient2.step10 {
    background: url(../images/femalepatient2.webp) no-repeat center center;

}

.home-animation .object.alarm {
    background: url(../images/alarm.webp) no-repeat center center;
    width: 4vw;
    height: 4vw;
    background-size: 0 auto;
    z-index: 99;
    top: auto;
    bottom: 18vw;
    margin-left: 20vw;
    -webkit-transition: all 0.25s linear;
    -moz-transition: all 0.25s linear;
    -o-transition: all 0.25s linear;
    transition: all 0.25s linear;
}

.home-animation .object.alarm.active {
    background-size: 0 auto;
    background-size: 100% auto;
}

.section.header.inverted ul#menu-header li a {
    color: #fff;
}

.object.background.night {
    background: #053C5E;
}


.button.small {
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
}

.button.circle {
    width: 50px;
    height: 50px;
    overflow: hidden;
    padding: 0;
    line-height: 50px;
    text-align: center;
    border: none;
    background: #fff;
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    position: absolute;
    bottom: -80px;
    left: 50%;
    margin-left: -25px;
}

.drop-arrow {
    width: 18px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, -50%);
}

.send-button {
    margin: 0 auto;
    text-align: center;
}

.content-hero .button.circle {
    position: absolute;
    bottom: -25px;
    left: 50%;
    margin-left: -25px;
}

.button.circle svg {
    display: inline-table;
    fill: #229be5;
    width: 14px;
    height: 14px;
    margin-right: -3px;
}


.hero-container.home-hero-container span {
    display: block;
    position: absolute;
    bottom: -120px;
    left: 50%;
    margin-left: -100px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.1em;
    font-size: 12px;
    width: 200px;
}

.content-hero .button.circle svg {
    margin-bottom: -6px;
}

.home-section-content {
    max-width: 32vw;
    text-align: center;
    margin: 29vw auto 0 auto;
}

.patients-section .home-section-content {
    max-width: 32vw;
    text-align: center;
    margin: 0 auto 0 auto;
}

.button.circle:hover {
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    width: 54px;
    height: 54px;
    line-height: 54px;
    margin-left: -27px;
}

.home-section-content.align-top {
    margin: 0 auto;
}

.section.section-alternate .home-section-content.align-top {
    margin-top: 5vw;
}


.modal-content {
    background-color: transparent;
    border: 0;
}

.btn-primary {
    color: #FFFEFE;
    background-color: #283779;
    border-color: #283779;
}


.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* 720 / 1280 = 0.5625 */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.modal-header {
    border: 0
}

.modal-header .btn-close {
    margin-right: -45px;
    font-size: 20px;
}

.modal-backdrop {
    --bs-backdrop-opacity: 0.6
}

/*END*/

/*Home Animation common CSS*/

.section {
    overflow: hidden;
    height: 56vw;
    position: relative;
    padding: 50px 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
}

.section p {
    color: rgba(0, 0, 0, 0.6);
    line-height: 2.0vw;
    font-size: 1.2vw;
    font-weight: 300;
    margin-bottom: 1.5rem;
}

.button {
    display: inline-table;
    background: none;
    padding: 0 30px;
    border: 1px solid #225be5;
    color: #225be5;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
    height: 50px;
    /*line-height: 52px;*/
    cursor: pointer;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.button:hover {
    background-color: #FF8E3A;
    border: 1px solid #FF8E3A;
    color: #fff;
    /*
    -webkit-transition: border-color .2s ease-out, background .2s ease-in;
    -moz-transition: border-color .2s ease-out, background .2s ease-in;
    -o-transition: border-color .2s ease-out, background .2s ease-in;
    transition: border-color .2s ease-out, background .2s ease-in;
  */
}

.button.orange {
    background: #FF8E3A;
    color: #fff;
    border: none;
}

.section-object {
    display: block;
    position: absolute;
}

.section.section-border {
    border-top: 1px solid #e5e5e5;
}

.section.section-home-alternate {
    background: rgba(34, 155, 229, 0.1) !important;
}

.section.section-alternate {
    background-color: #f9f9f9;
}

/*END*/

/*Home Animation-1*/

.section.section-mobile {
    height:59vw
}

.display-wrapper img {
    display: block;
    float: left;
    width: 11.6vw;
    height: 20.6vw;
}

.object.mobile-phone {
    width: 40vw;
    height: 30vw;
    background-image: url(../images/iphone.webp);
    background-size: auto 100% !important;
    background-position: center center;
    left: 50% !important;
    margin-left: -20vw;
    margin-top: -16vw;
    z-index: 99;
}

.mobile-phone-display {
    display: block !important;
    position: absolute !important;
    left: 50% !important;
    margin-top: -12.65vw;
    margin-left: -5.95vw;
    width: 11.6vw;
    height: 20.6vw;
    background: #000;
    z-index: 999;
    overflow: hidden;
}

.display-wrapper {
    display: block;
    float: left;
    width: 46.4vw;
    height: 20.6vw;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.icon-object {
    display: block;
    position: absolute;
    width: 10vw;
    height: 10vw;
    background-size: auto 60%;
    background-repeat: no-repeat;
    z-index: 1;
    left: 50%;
    margin-left: -5vw;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.icon-object.icon-mo1 {
    top: 13vw;
    background-image: url(../images/Paperless-image.webp);
}

.icon-object.icon-mo2 {
    top: 18vw;
    background-image: url(../images/Flex-Calendar-1.webp);
}

.icon-object.icon-mo3 {
    top: 23vw;
    background-image: url(../images/Insurance-1.webp);
}

.icon-object.icon-mo4 {
    top: 28vw;
    background-image: url(../images/Mobile-Check-1.webp);
}


.icon-object.icon-mo1.active {
    margin-left: -17vw;
}

.icon-object.icon-mo2.active {
    margin-left: 8vw;
}

.icon-object.icon-mo3.active {
    margin-left: -17vw;
}

.icon-object.icon-mo4.active {
    margin-left: 8vw;
}

.icon-object {
    display: block;
    position: absolute;
    width: 10vw;
    height: 10vw;
    background-size: auto 60%;
    background-repeat: no-repeat;
    z-index: 1;
    left: 50%;
    margin-left: -5vw;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.section-mobile .home-section-content {
    margin: 34vw auto 0 auto
}

.section.section-mobile .object{
    top:48%;
}

/*END*/


/*Home Animation-2*/
.object.multiple-locations {
    background-image: url(../images/locations.webp);
    width: 50vw;
    height: 25vw;
    margin-left: -25vw;
    margin-top: -22vw;
}

.object.location-flag {
    background-image: url(../images/flag.webp);
    background-size: auto 100%;
    left: 50%;
    top: 50%;
    width: 0;
    height: 0;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.object.location-flag.flag1 {
    margin-left: -20vw;
    margin-top: -14vw;
}

.object.location-flag.flag2 {
    margin-left: -15vw;
    margin-top: -3vw;
}

.object.location-flag.flag3 {
    margin-left: -7vw;
    margin-top: -20vw;
}

.object.location-flag.flag4 {
    margin-left: -7vw;
    margin-top: -9.5vw;
}

.object.location-flag.flag5 {
    margin-left: 12vw;
    margin-top: -17vw;
}

.object.location-flag.flag6 {
    margin-left: 13vw;
    margin-top: -6vw;
}

.object.location-flag.flag7 {
    margin-left: 19vw;
    margin-top: -19vw;
}

.object.location-flag.flag1.active {
    width: 3vw;
    height: 4.5vw;
    margin-left: -22vw;
    margin-top: -16vw;
}

.object.location-flag.flag2.active {
    width: 3vw;
    height: 4vw;
    margin-left: -17vw;
    margin-top: -5vw;
}

.object.location-flag.flag3.active {
    width: 4vw;
    height: 6vw;
    margin-left: -9vw;
    margin-top: -22vw;
}

.object.location-flag.flag4.active {
    width: 3vw;
    height: 4vw;
    margin-left: -8vw;
    margin-top: -11.5vw;
}

.object.location-flag.flag5.active {
    width: 3vw;
    height: 4vw;
    margin-left: 11vw;
    margin-top: -18vw;
}

.object.location-flag.flag6.active {
    width: 4vw;
    height: 5.5vw;
    margin-left: 12vw;
    margin-top: -7vw;
}

.object.location-flag.flag7.active {
    width: 3vw;
    height: 4vw;
    margin-left: 18vw;
    margin-top: -20vw;
}

.multiplelocation.section {
    height: 54vw;
}

.multiplelocation .home-section-content {
    margin: 29vw auto 0 auto;
}

/*END*/

/*Home Animation-3*/
.section .object {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.object.small-cloud {
    width: 12vw;
    height: 6vw;
    top: 5vw;
    background-image: url(../images/medium_cloud.webp);
    margin-left: auto;
    left: auto;
    right: 50%;
    margin: 0 !important;
    position: absolute;
    right: 8%;
    top: 5%;
}

.object.medium-cloud {
    width: 20vw;
    height: 10vw;
    background-image: url(../images/medium_cloud.webp);
    top: 8vw;
    margin: 0 !important;
    position: absolute;
    left: 8%;
    top: 5%;
}

.object.outlet-cloud {
    width: 40vw;
    height: 20vw;
    margin-top: -34vh;
    background-image: url(../images/cloud_outlet.webp);
    position: absolute;
    top: 15%;
    left: 20%;
    margin: 0 !important;
}

.object.plug-cloud {
    width: 50vw;
    height: 25vw;
    background-image: url(../images/homepage_plug.webp);
    margin-top: -25.5vh;
    left: auto;
    right: 0;
    position: absolute;
    top: 22%;
    margin: 0 !important;
}

/*END*/

/*Home Animation-4*/
.section-object.wall {
    background: url(../images/wall.webp) no-repeat center top;
    background-size: 100% auto;
    width: 100%;
    height: 60%;
    top: 0;
    margin-top: 0 !important;
}

.section-object.chair {
    background: url(../images/chair.webp) no-repeat center center;
    background-size: 100% auto;
    width: 30vw;
    height: 10vw;
    left: 50%;
    margin-left: -15vw;
    top: 14vw;
}

.home-section-content {
    max-width: 80%;
    text-align: center;
    margin: 29vw auto 0 auto;
}

/*END*/

/*Home Animation-5*/
.patients-section {
    background-image: url(../images/homepage_last_section.webp);
    background-position: bottom;
    border-top: 1px solid #e5e5e5;
    background-color: #f9f9f9;
    height: 59vw;
}

.image-carousel img {
    position: absolute;
    opacity: 0;
    margin-right: 5%;
    width: 3vw;
    transition: width 2s;
}

.image-carousel img.active {
    opacity: 1;
    transition: width 2s;
    width: 5vw;
}

.image-carousel img:nth-of-type(1) {
    left: 7%;
}

.image-carousel img:nth-of-type(2) {
    left: 20%;
}

.image-carousel img:nth-of-type(3) {
    left: 33%;
}

.image-carousel img:nth-of-type(4) {
    left: 51%;
}

.image-carousel img:nth-of-type(5) {
    left: 64%;
}

.image-carousel img:nth-of-type(6) {
    left: 78%;
}

.image-carousel img:nth-of-type(7) {
    left: 90%;
}

/*END*/

/*Sub Pages CSS*/

.pagebanner {
    background-color: #e8f5fc;
    margin-top: 70px;
}

.featuresimg,
.integrationimg,
.ropeimg {
    background-color: rgba(34, 155, 229, 0.1);
    padding: 0;
    height: 40vw;
    background-size: 100% auto;
    background-position: center 10vw;
    background-repeat: no-repeat;
    position: relative;
}

.featuresimg {
    background-image: url(../images/features-2.jpg);
    background-size: 65% auto;
}

.integrationimg {
    background-image: url(../images/integrations.jpg);
}

.ropeimg {
    background-image: url(../images/rope.jpg);
}

.customerlogo .col {
    text-align: center;
}

.integratedlogo .logobox {
    background-color: #fff;
    height: 140px;
    border: 1px solid #dddddd;
    margin: 0 0 20px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.integratedlogo img {
    max-width: 180px;
    max-height: 100px;
    margin: 0 auto;
}

.careerimg img {
    width: 100px
}

.btn {
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

textarea#message {
    height: 100px;
}





.subtextbox {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
}

.subtitlecolor {
    color: #229be5;
    font-size: 1rem;
    font-family: 'SegoeUISemiLight';
    font-weight: normal;
}

.light-gray {
    background-color: #f9f9f9;
}

.board-headshot {
    width: 30%;
    -moz-border-radius: 500px;
    -webkit-border-radius: 500px;
    border-radius: 500px;
    display: inline-table;
    margin: 0 auto 20px;
}

.read-or-hide {
    cursor: pointer;
    color: #ff6018;
    font-weight: 600;
}

a.morelink {
    color: #ff6018;
    font-weight: 600;
}

.morelink:hover {
    color: #ff6018;
}

.logobox h3 {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #f5f5f5;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: #666;
    padding: 5px 0;
    bottom: -30px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.logobox:hover h3 {
    bottom: 0;
}

img {
    max-width: 100%;
}

.success {
    color: #0f5132 !important;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    background-color: #d1e7dd;
    padding: 30px 17px;
    border-radius: 6px;
    border: 1px solid #badbcc;
}

.newsupdate a {
    color: #053c5e;
    font-weight: 600;
}

.newsupdate a.morelink {
    color: #ff6018;
}

.pricing-background {
    height: 100%;
    background: linear-gradient(90deg, #D3EBFA 50%, #fff 50%);
    color: #053C5E;
    font-weight: 600;
}

.roi-style {
    background: #D3EBFA;
    display: block;
    float: left;
    margin: 0;
    padding: 93px 50px;
}

.slider-calculator .slidervalue {
    background-color: #F3F9FD;
    display: block;
    padding: 12px 15px 7px;
    border: 0;
    width: 100%;
    font-weight: 600;
    color: #053C5E;
}

input[type=range] {
    -webkit-appearance: none;
    background: transparent;
    margin: 13px 0 0;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #829DAE;
    border-radius: 6px;
}

input[type=range]::-webkit-slider-thumb {
    border: 2px solid #fff;
    height: 17px;
    width: 17px;
    border-radius: 15px;
    background: #FF8E3A;
    cursor: pointer;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: #829DAE;
    border-radius: 6px;
}

input[type=range]::-moz-range-thumb {
    border: 2px solid #fff;
    height: 17px;
    width: 17px;
    border-radius: 15px;
    background: #FF8E3A;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]::-ms-fill-upper {
    background: #829DAE;
    border-radius: 6px;
}

input[type=range]::-ms-thumb {
    border: 2px solid #fff;
    height: 17px;
    width: 17px;
    border-radius: 15px;
    background: #FF8E3A;
    cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
    background: #829DAE;
}

input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
}



.totalvaluedisplay {
    font-family: 'SegoeUIBold';
    text-align: center;
    width: 100%;
    font-size: 3.8vw;
    color: #FF8E3A;
    font-weight: bold;
    height: 130px;
    border-bottom: 4px solid #053C5E;
    border-top: none;
    border-left: none;
    border-right: none;
}

.btn-outline-primary {
    padding-left: 40px;
    padding-right: 40px;
}

.btn-primary.large.orange {
    padding-left: 65px;
    padding-right: 65px;
}

.btn-outline-primary:link,
.btn-outline-primary:visited {
    background-color: inherit;
}

.btn-outline-primary:hover {
    background-color: #FF8E3A;
    border: 1px solid #FF8E3A;
    color: #fff;
}

.btn-outline-primary:hover a {
    color: #fff;
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.open>.dropdown-toggle.btn-outline-primary {
    background-color: #FF8E3A !important;
    border: 1px solid #FF8E3A !important;
}

.btn-outline-primary {
    height: 50px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2em;
}

.custom-dropdown-button {
    background-color: transparent;
    color: #229be5;
    border: 1px solid #dbe9f1;
    padding: 5px;
    font-family: 'SegoeUISemiLight';
}

.custom-dropdown-menu {
    background-color: #fff;
    border: 1px solid #229be5;
    color: #229be5;
    font-family: 'SegoeUISemilight';
}


.custom-dropdown-menu::after {
    content: "▼";
    /* Change the arrow icon */
    margin-left: 0.5rem;
    /* Adjust spacing */
}

.newsupdatedetail p {
    margin-bottom: 1.5rem;
    line-height: 1.8rem;
}

.newsupdatedetail h4 {
    font-size: 2.0rem;
}

.newsupdate p {
    line-height: 1.8rem;
}

.newsupdate h6 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 20px;
}

.flag-animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    /* Adjust as needed */
    overflow: hidden;
    position: relative;
}

.flag-image {
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.flag-image.visible {
    opacity: 1;
}

.integration.section {
    height: 57vw;
}

.integration .home-section-content {
    margin: 35vw auto 0 auto
}

.left-cloud {
    position: absolute;
    left: -50%;
    top: 13vh;
}

.cloud-outlet {
    position: absolute;
    left: -40%;
    top: 35vh;
}

.right-cloud {
    position: absolute;
    right: -40%;
    top: 13vh;
}

.homepage_plug {
    position: absolute;
    right: -60%;
    top: 43vh;
}

.fs18 {
    font-size: 18px;
}

.chair_move {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;

    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.3s ease;
    /* Adjust the transition speed as needed */

}

.advtextborder {
    border-top: 1px solid #b1daf2;
}

.advtext {
    text-align: center;
    border-top: 1px solid #f9f9f9;
    padding: 10px;
    color: #fff;
    font-size: 1.3em;
    background: #053c5e;
}

.advtext a {
    color: #fff;
    text-decoration: underline;
}

.flag {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
}

.flag.visible {
    opacity: 1;
}

.flag-container img:nth-of-type(1) {
    left: 7%;
    top: 24%;
    height: 62px;
}

.flag-container img:nth-of-type(2) {
    left: 16%;
    top: 68%;
    height: 62px;
}

.flag-container img:nth-of-type(3) {
    left: 33%;
    top: 41%;
    height: 55px;
}

.flag-container img:nth-of-type(4) {
    left: 32%;
    top: 0;
    height: 80px;
}

.flag-container img:nth-of-type(5) {
    left: 73%;
    top: 16%;
    height: 55px;
}

.flag-container img:nth-of-type(6) {
    left: 86%;
    top: 7%;
    height: 55px;
}

.flag-container img:nth-of-type(7) {
    left: 75%;
    top: 61%;
    height: 85px;
}

.captchabox a {
    color: #225be5 !important;
    font-size: 14px;
    position: relative;
    top: -5px;
}

.captchabox canvas {
    background-color: #fff;
    padding: 18px 12px 9px;
    border: 1px solid #f1f1f1;
}

.captchabox input {
    height: 67px;
    font-size: 17px;
}

.contactform .text-danger {
    margin-top: 3px;
}

/*END*/

/*Footer CSS*/
.footer,
.footer_bottom {
    font-size: 13px;
}

.bg-primary.footer {
    background-color: #053C5E !important;
    color: #fff;
    font-weight: 500;
}

.bg-primary.footer_bottom {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.footer a {
    color: #fff;
    padding: 8px 0;
    display: block;
    width: 100%;
    text-align: left;
    opacity: 0.7;
}

.footer a:hover {
    opacity: 1;
}

.footer a.linkedin {
    padding: 1px 0 6px 8px;
    border-radius: 3px;
}

.footer a.linkedin span {
    position: relative;
    top: 2px;
}

.footer .form-control {
    font-size: 0.9rem;
}

/*END Footer*/

@media (min-width: 992px) {
    .modal-lg {
        --bs-modal-width: 850px;
    }

}



@media screen and (max-width: 850px) {
    .home-hero-container h1 .subtitle {
        font-size: 4vw !important;
        line-height: 6.8vw;
      }


    header {
        position: inherit;
    }

    header.sticky {
        position: inherit;
    }

    .header-box {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }

    .header-box .logo {
        width: 150px;
        margin: 0 0 10px 15px;
    }

    .header-box .navbar-toggler,
    .header-box.navbar-toggler:focus {
        border: none;
        box-shadow: none;
    }

    .navbar-collapse {
        background-color: #053c5e;
    }

    .navbar-collapse.header-link a {
        width: 100%;
        border-bottom: 1px solid rgba(225, 225, 225, 0.1);
        color: #fff;
        display: block;
        margin: 0;
        padding: 20px;
        font-size: 17px;

    }

    .navbar-collapse.header-link a.morelink {
        color: #ff6018;
    }

    .navbar-collapse .nav-item.dropdown {
        width: 100%;
    }

    .navbar-toggler-icon {
        background: #ff7c55;
        right: 30px;
        position: absolute;
        top: 10px;
        border-top: 14px solid #e8f5fc;
        border-bottom: 12px solid #e8f5fc;
    }


    .navbar-toggler-icon::before {
        border-radius: 1px;
        height: 4px;
        width: 40px;
        background: #ff7c55;
        position: absolute;
        top: -12px;
        display: block;
        content: '';
    }

    .navbar-toggler-icon::after {
        border-radius: 1px;
        height: 4px;
        width: 40px;
        background: #ff7c55;
        position: absolute;
        top: 12px;
        display: block;
        content: '';
    }


    .dropdown-menu {
        display: block;
        position: relative;
        border: 0;
        box-shadow: none;
    }

    .dropdown a.dropdown-item a {
        padding-left: 50px;
        border: none;
    }

    .header-link .dropdown-menu a:hover {
        background-color: transparent;
        color: #1B71A1 !important;
    }

    .header-link a.active {
        font-weight: 600;
    }

    .nav-link .submenu {
        position: relative;
        padding: 0;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        background: #053c5e;
        box-shadow: none;
        border: none;
        top: 0;
        margin: 0;
    }
    
    .nav-link .submenu a {
        width: 100%;
        border-bottom: 1px solid rgba(225, 225, 225, 0.1);
        color: #fff;
        display: block;
        margin: 0;
        padding: 20px 20px 20px 60px;
        font-size: 17px;
    }

    .home-bg1,
    .home-bg2,
    .pagebanner {
        margin-top: 0 !important;
    }

    img {
        max-width: 100%;
    }

    .modal-header .btn-close {
        margin-right: -1.3rem;
    }




    .home-section-content {
        max-width: 70vw;
    }

    .featuresimg,
    .integrationimg,
    .ropeimg {
        height: 60vw
    }

    .subtextbox {
        bottom: 10%;
    }

    .subtextbox h2 {
        font-size: 4.5vw;
        margin-bottom: 0.5rem !important;
    }

    .subtextbox h5 {
        font-size: 3vw;
    }

    .logobox h3 {
        font-size: 13px !important;
        bottom: -53px
    }

    .roi-style {
        width: 100% !important;
    }

    .newsupdatedetail .btn {
        width: 100%;
    }

    .newsupdatedetail h4 {
        font-size: 1.4rem;
    }

    .btn-outline-primary,
    .btn-primary.large.orange {
        padding-left: 20px;
        padding-right: 20px;
    }

    .left-cloud {
        left: -30%;
        top: 10vh;
    }

    .cloud-outlet {
        left: -20%;
        top: 20vh;
    }

    .right-cloud {
        right: -20%;
        top: 10vh;
    }

    .homepage_plug {
        right: -40%;
        top: 23vh;
    }

    .image-carousel img.active {
        width: 10vw;
    }

    .flag-container img:nth-of-type(1) {
        height: 42px;
    }

    .flag-container img:nth-of-type(2) {
        height: 42px;
    }

    .flag-container img:nth-of-type(3) {
        height: 35px;
    }

    .flag-container img:nth-of-type(4) {
        height: 60px;
    }

    .flag-container img:nth-of-type(5) {
        height: 35px;
    }

    .flag-container img:nth-of-type(6) {
        height: 35px;
    }

    .flag-container img:nth-of-type(7) {
        height: 65px;
    }

}

@media screen and (max-width: 650px) {

    .left-cloud {
        left: -60%;
        top: 10vh;
    }

    .cloud-outlet {
        left: -50%;
        top: 20vh;
    }

    .right-cloud {
        right: -50%;
        top: 10vh;
    }

    .homepage_plug {
        right: -70%;
        top: 23vh;
    }
}